<template>
  <div class="loadMore">
    <span v-if="finished">暂无更多</span>
    <el-button v-else type="text" @click="load">加载更多</el-button>
  </div>
</template>
<script>
export default {
  props: {
    finished: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {

    }
  },
  methods: {
    load () {
      this.$emit('load')
    }
  }
}
</script>

<style lang="less" scoped>
.loadMore {
  // padding: 20px 0;
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  justify-content: center;
  height: 50px;
}
</style>
