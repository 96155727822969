<template>
  <div class="feedBack_content">
    <div class="my_feedback" v-for="item in feedbackList" :key="item.id">
      <div class="feedback_type_box display_flex alignItems_center flex_spaceBetween ">
        <h3>{{`您反馈的问题【${item.feedbackType}】${item.replyContent?'已有处理结果':'正在处理'}`}}</h3>
      </div>
      <p>
        <span class="type_title">您反馈的问题</span>
        <span class="create_time">{{$initTime(item.createdTime,'yyyy-MM-DD HH:mm')}}
        </span>
      </p>
      <p class="row_1">反馈: {{item.feedbackContent}}</p>
      <p v-if="item.documentPmid && item.feedbackType==='原文纠错'" class="row_2">
        文献: PMID:{{item.documentPmid}}, 标题:{{item.documentTitle}}, DOI:{{item.documentDoi}}
      </p>
      <div class="reback_box">
        <p class="reback_title type_title">
          我们的答复
        </p>
        <div class="reback_content">
          <div class="reback_left">
            {{item.replyContent}}
            <el-button v-if="item.documentPmid" type="text" class="toDocDetails_btn" size="mini"
                       @click.stop="toDocDetails(item)">查看文献
            </el-button>
            <el-button v-if="item.replyImg" type="text" class="toDocDetails_btn" size="mini"
                       @click.stop="seeReplyImg(item)">查看图片
            </el-button>
            <el-image v-show="false" :ref="'reply'+item.id" v-if="item.replyImg"
                      style="width: 10px; height: 10px" :src="item.replyImg"
                      :preview-src-list="[item.replyImg]">
            </el-image>
          </div>
          <div class="reback_time">{{$initTime(item.modifiedTime,'yyyy-MM-DD HH:mm')}}</div>
        </div>
      </div>
    </div>
    <LoadMore v-if="!isEmpty" :finished="finished" @load="load" />
    <!-- 确醒提示 -->
    <Empty v-if="isEmpty" content="暂无通知" />
  </div>
</template>

<script>
import { getFeedbackList } from '@/api/feedback.js'
import { getDocumentByPmid } from '@/api/article.js'
import { blankPagePar } from '@/utils/index'
import LoadMore from '@/components/loadMore/index.vue'

export default {
  props: {},
  components: {
    LoadMore
  },
  data () {
    return {
      feedbackList: [],
      isEmpty: false,
      pageConfig: {
        pageNum: 1,
        pageSize: 10
      },
      finished: false
    }
  },
  methods: {
    load () {
      this.pageConfig.pageNum++
      this.getFeedbackList()
    },
    seeReplyImg (item) {
      const imgBox = this.$refs['reply' + item.id][0]
      imgBox.$el.children[0].click()
      this.$myCount({
        name: '消息内容-访问',
        itemId: item.id
      })
    },
    async toDocDetails (item) {
      try {
        if (!item.documentPmid) return
        this.$myCount({
          name: '消息内容-访问',
          itemId: item.id
        })
        const res = await getDocumentByPmid({ pmid: item.documentPmid })
        blankPagePar('articleDetails', { id: res.data.id })
      } catch (error) {
        this.$message.error(error || '跳转失败')
      }
    },
    async getFeedbackList () {
      const res = await getFeedbackList(this.pageConfig)
      this.feedbackList = [...this.feedbackList, ...res.data]
      this.isEmpty = this.feedbackList.length === 0
      this.finished = res.data.length < this.pageConfig.pageSize
    }
  },
  created () {
    this.getFeedbackList()
  }
}
</script>

<style lang="less" scoped>
.feedBack_content {
  padding: 0 10px;
  min-height: calc(100vh - 310px);
  background-color: #fff;
}
.my_feedback {
  color: #666666;
  padding: 20px 10px;
  margin-bottom: 0;
  border-bottom: 1px dashed #ccc;
  cursor: default;
  // &:nth-last-child(2) {
  //   border-bottom: 0px;
  // }
  &:hover {
    // box-shadow: 0 0 8px 0px #cecece;
  }
  .feedback_type_box {
    margin-bottom: 15px;
    h3 {
      font-size: 16px;
    }
    .reTime {
      color: #4b639f;
    }
  }
  p {
    margin-bottom: 5px;
  }
  .create_time {
    margin-left: 20px;
  }
  .reback_box {
    margin-top: 15px;
    .reback_title {
      margin-bottom: 0;
    }
    .reTime {
      margin-left: 32px;
    }
    .toDocDetails_btn {
      margin-left: 10px;
    }
    .reback_content {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 0 20px;
      .reback_time {
        line-height: 28px;
      }
    }
  }
}
.type_title {
  color: #000;
  font-weight: 600;
}
/deep/.el-button--text {
  font-weight: 600;
}
</style>
