<template>
  <div class="mag_content">
    <div class="content" v-if="msgModuel.list.length!==0">
      <div class="msg_item"
           :class="{'is_img':item.contentType==='图片', 'is_text':item.contentType==='文本'  }"
           v-for="item in msgModuel.list" :key="item.id">
        <div class="img_content" v-if="['图文H5','图片'].includes(item.contentType)">
          <div @click="jump(item)" class="msg_img_box ">
            <el-image v-if="item.img" class="msg_img hand" :src="item.img"
                      :preview-src-list="item.pcUrl?[]:[item.img]">
            </el-image>
          </div>
        </div>
        <div v-if="item.contentType !== '图片'">
          <h1 class="fs16 mb10 msg_title">
            <span @click="jump(item)" :class="{jump_title: item.pcUrl}"> {{item.title}}</span>
          </h1>
          <p class="fs14 subtitle mb10">{{item.subtitle}}</p>
        </div>
        <p class="fs14 subtitle content_time">{{$initTime(item.modifiedTime,'yyyy-MM-DD HH:mm')}}
        </p>
      </div>
    </div>
    <Empty v-else content="暂无通知" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    category: {
      type: String,
      default: ''
    }
  },
  data () {
    return {

    }
  },

  methods: {
    jump (item) {
      this.$myCount({
        name: '消息内容-访问',
        itemId: item.id
      })
      if (!item.pcUrl) return
      window.open(item.pcUrl)
    }
  },
  computed: {
    ...mapGetters(['msgModuels']),
    msgModuel () {
      return this.msgModuels.find(item => item.category === this.category)
    }
  },
  created () {
  }
}
</script>

<style lang="less" scoped>
.mag_content {
  padding-bottom: 10px;
  margin-bottom: 20px;
  min-height: calc(100vh - 310px);
  background-color: #fff;
}
.content {
  padding: 0 10px;
  .msg_item {
    display: grid;
    grid-template-columns: 133px calc(100% - 153px);
    column-gap: 20px;
    padding: 20px 10px;
    border-bottom: 1px dashed #ccc;

    .msg_title {
      word-break: break-all;
    }
    .jump_title {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .msg_img_box {
      width: 133px;
      height: 80px;
      overflow: hidden;
    }
    /deep/ .msg_img {
      border-radius: 2px;
      object-fit: cover;
      &:hover {
        img {
          transform: scale(1.05);
        }
      }
      img {
        width: 100%;
        height: 100%;
        transform: scale(1);
        transition: all, 0.5s;
      }
    }
    .subtitle {
      color: #979797;
      line-height: 1.5em;
    }
  }
  .content_time {
    grid-column-start: span 2;
    text-align: end;
  }

  .is_img {
    grid-template-columns: 133px;
    column-gap: 0px;
  }
  .is_text {
    grid-template-columns: 100%;
    column-gap: 0px;
  }
}
</style>
