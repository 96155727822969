<template>
  <div class="msg_content">
    <div class="row">
      <div class="content">
        <div class="left_nav no_select">
          <div class="nav hand" @click="navClick(item)"
               :class="{activate: category === item.category}" v-for="item in msgModuelList"
               :key="item.category">
            <span>{{item.name}}</span>
            <i v-if="item.isMark" class="isMark"></i>
          </div>
        </div>
        <div class="right_view">
          <component :category="category" :is="categoryMap.componentName"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import merge from 'webpack-merge'
import { setMarkTime } from '@/utils/storage'
import Feedback from './components/feedback.vue'
import DefaultMsg from './components/defaultMsg.vue'
export default {
  props: {},
  components: {
    Feedback,
    DefaultMsg
  },
  data () {
    return {
      category: null
    }
  },
  methods: {
    navClick (item) {
      if (item.category === this.category) return
      item.isMark = false
      this.category = item.category

      this.$router.push({
        query: merge(this.$route.query, { category: item.category })
      })
    }
  },
  computed: {
    ...mapGetters(['msgModuels']),
    msgModuelList () {
      return this.msgModuels.filter(msg => !['5'].includes(msg.category))
    },
    categoryMap () {
      const maps = {
        2: {
          componentName: 'Feedback'
        },
        default: {
          componentName: 'DefaultMsg'
        }
      }
      let key = ''
      switch (true) {
        case this.category === '2':
          key = '2'
          break
        default:
          key = 'default'
          break
      }
      return maps[key]
    },
    countName () {
      const map = {
        1: 'PC-Info团队访问',
        2: 'PC-消息反馈访问',
        3: 'PC-数据库更新访问',
        4: 'PC-活动福利访问'
      }
      return map[this.category]
    }
  },
  watch: {
    '$route.query.category': {
      handler (newVal) {
        this.category = newVal || '1'
        this.$store.commit('msg/updateMark', {
          category: this.category,
          type: false
        })
        this.$nextTick(() => {
          setMarkTime(this.msgModuelList.find(msg => msg.category === this.category).markName)
          if (this.countName) {
            this.$myCount(this.countName)
          }
        })
      },
      immediate: true
    }
  },
  created () {
  }
}
</script>

<style lang="less" scoped>
.msg_content {
  min-height: calc(100vh - 255px);
  background-color: #f5f7fb;
}
.content {
  display: grid;
  grid-template-columns: 177px calc(100% - 197px);
  column-gap: 20px;
  align-items: start;
  .left_nav {
    position: sticky;
    top: 82px;
    width: 100%;
    padding: 30px;
    background-color: #fff;
    border-radius: 2px;
    .nav {
      position: relative;
      // text-align: center;
      padding: 10px 0;
      font-size: 17px;
      padding-left: 20px;
    }
    .isMark {
      position: absolute;
      top: 50%;
      right: -2px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #fc493a;
      transform: translate(0, -50%);
    }
    .activate {
      color: #2b72e0;
      font-weight: 600;
      &::before {
        content: '';
        position: absolute;
        left: 0px;
        top: 50%;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        border: 2px solid #2b72e0;
        transform: translate(0, -50%);
      }
    }
  }
  .right_view {
    border-radius: 4px;
  }
}
</style>
